<template>
  <form  @submit.prevent="createUpdateBio" >

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.bio.msg?'text-danger':''" for="email">
                                A brief summary of your knowledge, abilities, interests and experience
                                <span v-if="fv.bio.msg" > | {{fv.bio.msg}}</span>
                            </label>
                            <textarea class="form-control with-light" v-model.trim="fv.bio.val"
                            @keyup="validate.validateFormData(fv,'bio',true)" rows="7"></textarea>
                        </div>
                    </div>


                    <div class="col-12" v-if="fv.reqFeedback.status">

                            <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                            <button type="submit" class="btn btn-save col-12">Update account profile</button>
                    </div>
                    <base-spinner class="ml-3" v-else></base-spinner>

  </form>

</template>


<script>
import { ref,reactive,inject } from 'vue'
import { useStore } from 'vuex';
import validators from '@/hooks/validation'

export default{

  name: 'CreateProfileBio',
  emits: ['emitUpdateBio'],
  setup( _ , { emit } ) {

    // get the global vuex store values
    const store = useStore();

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    // use the dispatch function to call an action that calls a mutation
    // to set the data of for the job ad
    const oldAccountData = ref(false);
    oldAccountData.value = store.getters["account/getAccountData"];


     // create variables for the values to be submitted
    const fv = reactive({
        bio : {status:false, val:oldAccountData.value.bio, msg:false, validate:["required"]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })



    // create the account using async
    async function createUpdateBio(){

        // end the action of making a submission, and hide loader
        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();
        formData.append("fv_bio", fv.bio.val);


        // post the data using axios
        try{
            const response = await axios.put(
                coreUrl.backendUrl+'create_individual_bio_details',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data;

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = responseData.message.req_msg;
            fv.reqFeedback.val = "success";
            fv.showSubmit.status = false;


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

            emit('emitUpdateBio', fv.bio.val);

        }catch(error){

            // load the error to the appropriate field
            const fv_msg = error.response.data.message.fv_msg;
            const req_msg = error.response.data.message.req_msg;

            for(const key in fv_msg){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = fv_msg[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

        }

    }

    return {
        fv,
        validate,
        createUpdateBio
     }
  }
}
</script>
