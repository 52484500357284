<template>
  <form  @submit.prevent="createUpdateQualification" >

            <div class="row px-3" >
                <div class="col-xl-12 col-lg-12 col-12 pt-3">
                    <div class="form-group">
                        <label :class="fv.type.msg?'text-danger':''" for="email">
                             What type of Qualification?
                            <span v-if="fv.type.msg" > | {{ fv.type.msg }}</span>
                        </label>
                        <select class="form-control with-light" style="height:36px"
                                v-model.trim="fv.type.val"
                                @change="validate.validateFormData(fv,'type',true)">
                               <option value="">Select qualification type</option>
                               <option v-for="type in qualificationTypes"
                                       :value="type.value"
                                       :key="type.value"
                               >
                                   {{type.alias}}
                               </option>
                         </select>
                    </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-12 pt-3" v-if="fv.type.val">
                    <div>
                            <div class="form-group">
                                <label :class="fv.title.msg?'text-danger':''" for="title">
                                    What is the name of your {{ fv.type.val.replace('_',' / ') }}
                                    <span v-if="fv.title.msg" > | {{ fv.title.msg }}</span>
                                </label>
                                <input type="text" :placeholder="'Enter ' + fv.type.val.replace('_',' / ') + ' name'"
                                       required v-model.trim="fv.title.val"
                                       @keyup="validate.validateFormData(fv,'title',true)"
                                       class="form-control with-light" id="title">
                            </div>
                    </div>

                    <div  class="pt-3">
                            <div class="form-group">
                                <label :class="fv.institution.msg?'text-danger':''" for="title">
                                    The name of your institution
                                    <span v-if="fv.institution.msg" > | {{ fv.institution.msg }}</span>
                                </label>
                                <input type="text" placeholder="Enter institution name e.g. Babcock University, Harvard univeristy "
                                       required v-model.trim="fv.institution.val"
                                       @keyup="validate.validateFormData(fv,'institution',true)"
                                       class="form-control with-light" id="title">
                            </div>
                    </div>


                    <div class="pt-3">
                        <div class="form-group">
                            <label :class="fv.startYear.msg?'text-danger':''" for="email">
                                Start year
                                <span v-if="fv.startYear.msg" > | {{ fv.startYear.msg }}</span>
                            </label>
                            <select class="form-control with-light" style="height:36px"
                                    v-model.trim="fv.startYear.val"
                                    @change="validate.validateFormData(fv,'startYear',true)">
                                   <option value="">Select year</option>
                                   <option v-for="year in eligibleYears"
                                           :value="year"
                                           :key="year"
                                   >
                                       {{year}}
                                   </option>
                             </select>
                        </div>
                    </div>


                    <div class="pt-3">
                        <div class="form-group">
                            <label :class="fv.endYear.msg?'text-danger':''" for="email">
                                End year
                                <span v-if="fv.endYear.msg" > | {{ fv.endYear.msg }}</span>
                            </label>
                            <select class="form-control with-light" style="height:36px"
                                    v-model.trim="fv.endYear.val"
                                    @change="validate.validateFormData(fv,'endYear',true)">
                                   <option value="">Select end year</option>
                                   <option v-for="year in eligibleYears"
                                           :value="year"
                                           :key="year"
                                   >
                                       {{year}}
                                   </option>
                             </select>
                        </div>
                    </div>


                    <div class="pt-3" v-if="false">
                        <div class="form-group">
                            <label :class="fv.description.msg?'text-danger':''" for="email">
                                Description (optional)
                                <span v-if="fv.description.msg" > | {{fv.description.msg}}</span>
                            </label>
                            <textarea class="form-control with-light" v-model.trim="fv.description.val"
                            @keyup="validate.validateFormData(fv,'description',true)" rows="7"></textarea>
                        </div>
                    </div>

                </div>

                <div class="col-12" v-if="fv.reqFeedback.status">
                    <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                </div>

                <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                    <button type="submit" class="btn btn-save col-12">Update qualification</button>
                </div>
                <base-spinner class="ml-3" v-else></base-spinner>

            </div>
  </form>

</template>


<script>
import { ref,reactive,computed,inject } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation'

export default{
  name: 'CreateProfileQualification',
  emits: ['emitUpdateResume'],
  props: {
      updateId : {
          type:String,
          default:"none"
      }
  },
  setup(props, { emit }) {

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      // to set the data of for the job ad
      const previousQualifications = ref(JSON.parse(store.getters["account/getAccountData"]["qualifications"]));
      const updateQualifications =  Array.isArray(previousQualifications.value)?ref(previousQualifications.value.filter((item)=> item.id === props.updateId)):ref([]);


      // get the validators
      const validate = validators();

      // get the axios object
      const axios = inject('axios');  // inject axios

      //get the urls
      const coreUrl = inject('coreUrl');

      // create variables for the values to be submitted
       const fv = reactive({
           type : {status:false, val:props.updateId == "none" ?"":updateQualifications.value[0]["type"], msg:false, validate:["required","safeSpaceInput"]},
           title : {status:false, val:props.updateId == "none" ?"":updateQualifications.value[0]["title"], msg:false, validate:["required","safeSpaceInput"]},
           institution : {status:false, val:props.updateId == "none" ?"":updateQualifications.value[0]["institution"], msg:false, validate:["required","safeSpaceInput"]},
           startYear: {status:false, val:props.updateId == "none" ?"":+updateQualifications.value[0]["startYear"], msg:false, validate:["required","numeric"]},
           endYear: {status:false, val:props.updateId == "none" ?"":+updateQualifications.value[0]["endYear"], msg:false, validate:["required","numeric"]},
           description : {status:false, val:props.updateId == "none" ?"":updateQualifications.value[0]["description"], msg:false, validate:["required",{option:"textMax",val:300}]},
           reqFeedback : {status:false, val:"", msg:false},
           showSubmit : {status:false, val:true}
       })


       const eligibleYears = computed(function(){

           let date = new Date()
           let years = []
           let currentYear = date.getFullYear();

           if(fv.type.val == 'license')
           {
               for( let i = +currentYear + 60 ; i >=  +currentYear - 60; i-- ){
                   years.push(i)
               }
           } else {
               for( let i = +currentYear; i >=  +currentYear - 80; i-- ){
                   years.push(i)
               }
           }


           return years;
       })

       const qualificationTypes = ref([
           {value:"doctorate",alias:"Doctorate"},
           {value:"masters",alias:"Masters"},
           {value:"bachelors",alias:"Bachelors"},
           {value:"higher_diploma",alias:"Higher Diploma"},
           {value:"certification",alias:"Certification"},
           {value:"license",alias:"License"},
           {value:"vocation",alias:"Vocation"}
       ]);

       const qualificationCategory = {
           doctorate:"Academic",
           masters:"Academic",
           bachelors:"Academic",
           higher_diploma:"Academic",
           certification:"Certification",
           license:"Special Licenses",
           vocation:"Vocational"
       };



    // create the account using async
    async function createUpdateQualification(){


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_id", props.updateId);
            formData.append("fv_category", qualificationCategory[fv.type.val]);
            formData.append("fv_type", fv.type.val);
            formData.append("fv_title", fv.title.val);
            formData.append("fv_institution", fv.institution.val);
            formData.append("fv_startYear",  fv.startYear.val);
            formData.append("fv_endYear",  fv.endYear.val);
            formData.append("fv_description", fv.description.val);

            const updateLink = props.updateId == "none"?'create_individual_qualification_details':'edit_individual_qualification_details';

            // post the data using axios
            try{
                const response = await axios.put(
                    coreUrl.backendUrl+updateLink,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

            // If successful, get the resppnse data
            const responseData = response.data;

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = responseData.message.req_msg;
            fv.reqFeedback.val = "success";
            fv.showSubmit.status = false;


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;


            emit('emitUpdateResume', 'qualifications', responseData.data)


        }catch(error){

            // load the error to the appropriate field
            const fv_msg = error.response.data.message.fv_msg;
            const req_msg = error.response.data.message.req_msg;

            for(const key in fv_msg){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = fv_msg[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

        }

    }

    return {
        fv,
        validate,
        qualificationTypes,
        eligibleYears,
        createUpdateQualification
     }
  }
}
</script>
