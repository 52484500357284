<template>
    <!-- ============================ Page Title Start================================== -->
    <div class="page-title  bl-dark" >
        <div class="container">

        </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg pt-4">
        <div class="container-fluid">
            <div class="row m-0">


                <!-- <account-sidebar :editProfile="true" v-if="oldAccountData"> -->
                <account-sidebar :editProfile="true" activeBlock="profile" >
                </account-sidebar>

                <!-- Item Wrap Start -->
                <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Breadcrumbs -->
                            <div class="bredcrumb_wrap ">
                                <nav aria-label="breadcrumb">
                                  <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Resume</li>
                                  </ol>
                                </nav>
                            </div>
                        </div>
                    </div>


                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-sm-12">

                            <div class="_job_detail_box">

                            <div class="_wrap_box_slice " >
                                <div class="_job_detail_single">
                                    <h4>
                                        Profile summary

                                        <div class="float-right cursor-pointer" @click="updateModalData('bio','none')" data-toggle="modal" data-target="#updateResume">
                                            <span class="update-style pr-1"> update </span>
                                          <i class="bi bi-plus-circle-fill"></i>
                                        </div>

                                    </h4>

                                    <div class="qa-skill-box pl-2 pr-2 mt-4 pt-3 pb-3" v-if="oldAccountData.bio">
                                        <span> {{ oldAccountData.bio.substr(0,520) }} </span>
                                        <span v-if="!renderOptions.bio.overflow && oldAccountData.bio.length > 510"> .... </span>
                                        <span v-if="renderOptions.bio.overflow"> {{ oldAccountData.bio.substr(520) }} </span>

                                        <div v-if=" oldAccountData.bio.length > 510" class="cursor-pointer p-2 text-center"
                                              @click="renderOptions.bio.overflow = !renderOptions.bio.overflow">
                                                 <b>
                                                     <u>
                                                        <i class="bi " :class="renderOptions.bio.overflow?'bi-dash-circle-fill':'bi-plus-circle-fill'"></i>
                                                       {{ renderOptions.bio.overflow ? ' See less' : ' See more' }}
                                                     </u>
                                                 </b>
                                         </div>
                                    </div>

                                     <p class="qa-skill-box mt-5 py-4 text-center" v-else>
                                         You are yet to update your summary.
                                         <span class="cursor-pointer" @click="updateModalData('bio','none')" data-toggle="modal" data-target="#updateResume">
                                             <u> Get started </u>
                                         </span>
                                     </p>
                                </div>

                            </div>

                            <!-- Award & Experience -->
                            <div class="_wrap_box_slice">
                                <div class="_job_detail_single">
                                    <h4>
                                        Professional Experience

                                        <div class="float-right cursor-pointer" @click="updateModalData('work','none')" data-toggle="modal" data-target="#updateResume">
                                            <span class="update-style pr-1">update </span>
                                          <i class="bi bi-plus-circle-fill"></i>
                                        </div>

                                    </h4>

                                    <div v-if="compWorkExperience.length > 0">
                                        <ul class="qa-skill-list" v-for="(work, index) in compWorkExperience" :key="work.id">
                                        <!-- Single List -->
                                        <li v-if="index > 1?renderOptions.work.overflow:true">
                                            <div class="qa-skill-box">
                                                <h4 class="qa-skill-title">
                                                    <span>{{ work.organization }}</span>
                                                    <span class=" qa-time ">
                                                        {{ monthList[JSON.parse(work.startDate).month] }} -
                                                        {{ JSON.parse(work.startDate).year }}
                                                                    to
                                                        {{ JSON.parse(work.previousRole)?monthList[JSON.parse(work.endDate).month]+' - ':'active' }}
                                                        {{ JSON.parse(work.previousRole)?JSON.parse(work.endDate).year :''}}
                                                    </span>
                                                </h4>


                                                <h5 class="qa-subtitle">
                                                    {{ work.title }}

                                                    <span class="text-secondary">
                                                        ( {{ work.type.replace("_","-") }} )
                                                    </span>
                                                </h5>
                                                <div class="qa-content">
                                                    <p>
                                                        {{ work.summary }}
                                                      </p>
                                                </div>
                                                <div class="cursor-pointer" >
                                                    <span class="p-1 col-lg-1 col-4 btn btn-outline-primary rounded tiny-font" @click="updateModalData('work',work.id)" data-toggle="modal" data-target="#updateResume">
                                                        <i class="bi bi-pencil-square"></i> Update
                                                     </span>

                                                    <span class="ml-2 col-lg-1 col-4 p-1 btn btn-outline-secondary  rounded tiny-font"
                                                          @click="updateDeleteData(work.id,'work_experience')"  data-toggle="modal" data-target="#promptBox">
                                                        <i class="bi bi-trash-fill"></i> Delete
                                                    </span>

                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                        <div v-if=" compWorkExperience.length > 1 " class="cursor-pointer p-2 text-center"
                                              @click="renderOptions.work.overflow = !renderOptions.work.overflow">
                                                 <b>
                                                     <u>
                                                        <i class="bi " :class="renderOptions.work.overflow?'bi-dash-circle-fill':'bi-plus-circle-fill'"></i>
                                                       {{ renderOptions.work.overflow ? ' See less' : ' See more' }}
                                                     </u>
                                                 </b>
                                         </div>
                                    </div>
                                    <p class="qa-skill-box mt-5 py-4 text-center" v-else>
                                        You are yet to update your work experience.
                                        <span class="cursor-pointer" @click="updateModalData('work','none')" data-toggle="modal" data-target="#updateResume">
                                            <u> Get started </u>
                                        </span>
                                    </p>


                                </div>
                            </div>

                            <!-- Completed Projects -->
                            <div class="_wrap_box_slice">
                                <div class="_job_detail_single">
                                    <h4>
                                        Accomplishments

                                        <div class="float-right cursor-pointer" @click="updateModalData('accomplishment','none')" data-toggle="modal" data-target="#updateResume">
                                            <span class="update-style pr-1">updates </span>
                                          <i class="bi bi-plus-circle-fill"></i>
                                        </div>

                                    </h4>
                                    <div class="row " v-if="compAccomplishments.length > 0">
                                        <!-- Single Job List -->
                                        <div class="col-lg-6 col-md-6 col-sm-12" v-for="( accomp , index ) in compAccomplishments" :key="accomp.id">
                                            <div class="_jb_list72" v-if="index > 3?renderOptions.accomplishment.overflow:true">
                                                <div class="jobs-like bookmark">
                                                    <label class="cursor-pointer"  >
                                                        <a :href="'http://'+accomp.link" target="_blank" >
                                                            <i class="bi bi-link-45deg pr-2"></i>
                                                        </a>
                                                        <i class="bi bi-pencil pr-2" @click="updateModalData('accomplishment',accomp.id)"  data-toggle="modal" data-target="#updateResume"></i>
                                                        <i class="bi bi-trash-fill" @click="updateDeleteData(accomp.id,'accomplishments')"  data-toggle="modal" data-target="#promptBox"></i>
                                                    </label>
                                                </div>
                                                <div class="_jb_list72_flex">
                                                    <div class="_jb_list72_first">
                                                        <div class="_jb_list72_yhumb small-thumb">
                                                            <img src="https://via.placeholder.com/100x100" class="img-fluid" alt="">
                                                        </div>
                                                    </div>
                                                    <div class="_jb_list72_last">
                                                        <div class="_times_jb">{{ accomp.type.replace("_", " / ")}}</div>
                                                        <h4 class="_jb_title"><a href="#">{{ accomp.name }}</a></h4>
                                                        <div class="_times_jb">{{ accomp.client }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if=" compAccomplishments.length > 3 " class="cursor-pointer p-2 text-center col-12"
                                              @click="renderOptions.accomplishment.overflow = !renderOptions.accomplishment.overflow">
                                                 <b>
                                                     <u>
                                                        <i class="bi " :class="renderOptions.accomplishment.overflow?'bi-dash-circle-fill':'bi-plus-circle-fill'"></i>
                                                       {{ renderOptions.accomplishment.overflow ? ' See less' : ' See more' }}
                                                     </u>
                                                 </b>
                                         </div>

                                    </div>
                                    <p class="qa-skill-box mt-5 py-4 text-center" v-else>
                                        You are yet to update your completed projects.
                                        <span class="cursor-pointer" @click="updateModalData('accomplishment','none')"  data-toggle="modal" data-target="#updateResume">
                                            <u> Get started </u>
                                        </span>
                                    </p>
                                </div>
                            </div>


                            <!-- Education & Qualification -->
                            <div class="_wrap_box_slice">
                                <div class="_job_detail_single">
                                    <h4>
                                        Qualifications & expertise

                                        <div class="float-right cursor-pointer" @click="updateModalData('qualification','none')" data-toggle="modal" data-target="#updateResume">
                                            <span class="update-style pr-1">updates </span>
                                          <i class="bi bi-plus-circle-fill"></i>
                                        </div>
                                    </h4>
                                    <div v-if="compQualifications.length > 0"  >

                                        <div v-for="category in compQualificationCategory" :key="category">
                                            <div v-if="compQualifications.filter((item)=>item.category == category)" >
                                                <div class="text-secondary" v-if="renderOptions.qualification.overflow">
                                                    <u > <b> {{ category }} </b> </u>
                                                </div>
                                                <ul class="qa-skill-list" v-for="(qualify, index) in compQualifications" :key="qualify.id">

                                                    <!-- v-if="index > 3?renderOptions.accomplishment.overflow:true" -->
                                                    <!-- Single List -->
                                                    <li v-if="qualify.category == category && (index > 2?renderOptions.qualification.overflow:true)">
                                                        <div class="qa-skill-box">
                                                            <h4 class="qa-skill-title">
                                                                {{ qualify.title }}
                                                                <span class="qa-time text-primary">
                                                                    {{ qualify.type == 'license' ? 'Valid from ' : '' }}
                                                                    {{ qualify.startYear }}
                                                                        -
                                                                    {{  qualify.endYear }}
                                                                </span>
                                                            </h4>
                                                            <p class="text-secondary">
                                                                <b> {{ qualify.institution }}, </b>
                                                                {{ qualify.type }}
                                                            </p>

                                                            <div class="cursor-pointer" >
                                                                <span class="p-1 col-lg-1 col-4 btn btn-outline-primary rounded tiny-font" @click="updateModalData('qualification',qualify.id)" data-toggle="modal" data-target="#updateResume">
                                                                    <i class="bi bi-pencil-square"></i> Update
                                                                 </span>

                                                                <span class="ml-2 col-lg-1 col-4 p-1 btn btn-outline-secondary  rounded tiny-font"
                                                                      @click="updateDeleteData(qualify.id,'qualifications')"  data-toggle="modal" data-target="#promptBox">
                                                                    <i class="bi bi-trash-fill"></i> Delete
                                                                </span>

                                                            </div>

                                                        </div>
                                                    </li>



                                                </ul>
                                            </div>
                                        </div>
                                        <div v-if=" compQualifications.length > 2 " class="cursor-pointer p-2 text-center col-12"
                                              @click="renderOptions.qualification.overflow = !renderOptions.qualification.overflow">
                                                 <b>
                                                     <u>
                                                        <i class="bi " :class="renderOptions.qualification.overflow?'bi-dash-circle-fill':'bi-plus-circle-fill'"></i>
                                                       {{ renderOptions.qualification.overflow ? ' See less' : ' See more' }}
                                                     </u>
                                                 </b>
                                         </div>
                                    </div>
                                    <p class="qa-skill-box mt-5 py-4 text-center" v-else>
                                        You are yet to update your qualifications.
                                        <span class="cursor-pointer" @click="updateModalData('qualification','none')"  data-toggle="modal" data-target="#updateResume">
                                            <u> Get started </u>
                                        </span>
                                    </p>
                                </div>

                            </div>


                            <!-- Education & Qualification -->
                            <div class="_wrap_box_slice">


                                <div class="_job_detail_single">
                                    <h4>
                                        Skills & Expertise
                                        <div class="float-right cursor-pointer" @click="updateModalData('skill','none')" data-toggle="modal" data-target="#updateResume">
                                            <span class="update-style pr-1">updates </span>
                                          <i class="bi bi-plus-circle-fill"></i>
                                        </div>
                                    </h4>
                                    <div v-if="compSkills.length > 0">
                                        <div v-for="level in compSkillsLevel" :key="level">
                                            <div class="text-secondary">
                                                <u> <b> {{ level }} </b> </u>
                                            </div>
                                            <ul class="skilss pt-2" >
                                                <li v-for="sk in compSkills.filter((item) => item.level === level)" :key="sk.id"  class="bg-secondary rounded">
                                                    <a href="javascript:void(0);" >
                                                        {{ sk.skill }}
                                                        <i class="pl-2 bi bi-pencil-square"
                                                           @click="updateModalData('skill',sk.id)"
                                                           data-toggle="modal" data-target="#updateResume">
                                                        </i>
                                                        <i class="pl-2 bi bi-trash-fill"
                                                           @click="updateDeleteData(sk.id,'skills')"
                                                           data-toggle="modal" data-target="#promptBox"
                                                        > </i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p class="qa-skill-box mt-5 py-4 text-center" v-else>
                                        You are yet to update your skills.
                                        <span class="cursor-pointer" @click="updateModalData('skill','none')" data-toggle="modal" data-target="#updateResume" >
                                            <u> Get started </u>
                                        </span>
                                    </p>
                                </div>

                            </div>


                        </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->

    <base-modal mId="updateResume" :mSize="modalSize"  >
         <template #header-left-slot> {{ modalTitle }} </template>
         <template #default>

             <create-profile-bio @emit-update-bio="updateBio" v-if="modalOptions.bio.status">
             </create-profile-bio>

             <create-profile-work
                @emit-update-resume="updateResume"
                :key="modalOptions.work.id"
                :updateId="modalOptions.work.id"
                v-if="modalOptions.work.status">
             </create-profile-work>

             <create-profile-accomplishment
                @emit-update-resume="updateResume"
                :key="modalOptions.accomplishment.id"
                :updateId="modalOptions.accomplishment.id"
                v-if="modalOptions.accomplishment.status">
             </create-profile-accomplishment>

             <create-profile-qualification
                @emit-update-resume="updateResume"
                :key="modalOptions.qualification.id"
                :updateId="modalOptions.qualification.id"
                v-if="modalOptions.qualification.status">
             </create-profile-qualification>

             <create-profile-skill
                @emit-update-resume="updateResume"
                :key="modalOptions.skill.id"
                :updateId="modalOptions.skill.id"
                v-if="modalOptions.skill.status">
            </create-profile-skill>

         </template>
    </base-modal>


    <base-modal mId="promptBox" mSize="" :mFooter="true"  >
         <template #header-left-slot> {{ promptTitle }} </template>
         <template #header-right-slot> . </template>
         <template #default>

              <div class="m-3">
                  {{ promptContent }}
              </div>

             <div class="">
                 <div class="col-12" v-if="fv.reqFeedback.status">
                     <base-list-tag  :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                 </div>
                 <base-spinner class="m-3" v-if="!fv.showSubmit.val"></base-spinner>
            </div>

         </template>
          <template #footer-left-slot>
              <form  @submit.prevent="deleteProfileItem" >
                  <div  v-if="fv.showSubmit.status">
                      <button type="submit" class="btn btn-primary">yes</button>
                  </div>
              </form>
          </template>
          <template #footer-right-slot>
                  <button type="button" @click="closeDeleteBox" data-dismiss="modal" class="btn btn-outline-primary"> {{ promptClose }}</button>
          </template>
    </base-modal>




</template>

<script>
// @ is an alias to /src
import { ref, reactive, computed, inject } from 'vue';
import { useStore } from 'vuex';
import AccountSidebar from '@/components/account/AccountSidebar.vue';
import CreateProfileBio from '@/components/forms/profile/CreateProfileBio.vue';
import CreateProfileWork from '@/components/forms/profile/CreateProfileWork.vue';
import CreateProfileAccomplishment from '@/components/forms/profile/CreateProfileAccomplishment.vue';
import CreateProfileQualification from '@/components/forms/profile/CreateProfileQualification.vue';
import CreateProfileSkill from '@/components/forms/profile/CreateProfileSkill.vue';

export default {
  name: "ProfileWork",
  components:{
    AccountSidebar,
    CreateProfileBio,
    CreateProfileWork,
    CreateProfileAccomplishment,
    CreateProfileQualification,
    CreateProfileSkill
  },
  setup(){

      // get the global vuex store values
      const store = useStore();
      const axios = inject('axios');
      const coreUrl = inject('coreUrl');

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",true);
      store.dispatch("globals/executeSetFooterMenu",false);


      const oldAccountData  = ref(false);
      const reqError  = ref("Loading data ....");

      oldAccountData.value = store.getters["account/getAccountData"];
      reqError.value = oldAccountData.value?"":"Account does not exist"


      //       // use axios to get the data
      // axios.get(
      //           coreUrl.backendUrl+'get_ind_profile_data',
      //           {withCredentials: true}).then((response) =>{
      //
      //           const result = response.data.data;
      //
      //           // use the dispatch function to call an action that calls a mutation
      //           // to set the data of for the job ad
      //           store.dispatch("account/executeSetAccountData",result);
      //           oldAccountData.value = store.getters["account/getAccountData"];
      //
      //
      //   }).catch((error)=>{
      //               reqError.value = error.response.data.message.req_msg?error.response.data.message.req_msg:"Account does not exist";
      //               oldAccountData.value = false;
      //   })


        const renderOptions = reactive({
            bio:{overflow:false},
            work:{overflow:false},
            qualification:{overflow:false},
            accomplishment:{overflow:false},
            skill:{overflow:false}
        });


      const modalOptions = reactive({
          bio:{size:"lg",title:"Update resume summary",status:false},
          work:{id:'none',size:"lg",title:"Update work experience",status:false},
          qualification:{id:'none',size:"lg",title:"Update qualification",status:false},
          accomplishment:{id:'none',size:"lg",title:"Update accomplishment",status:false},
          skill:{id:'none',size:"lg",title:"Update skill",status:false}
      });

      const modalKeys = Object.keys(modalOptions);
      const modalSize = ref("");
      const modalTitle = ref("");


      function updateModalData(item,id){

          modalKeys.forEach((key) => {
              modalOptions[key]['status'] = false;
          });

          modalSize.value = modalOptions[item]['size'];
          modalTitle.value = modalOptions[item]['title'];
          modalOptions[item]['status'] = true;


          if(id != 'none'  ){
              modalOptions[item]['id'] = id;
          }
      }

      function updateBio(bio){

          oldAccountData.value.bio = bio;

      }

      const compWorkExperience = computed(function(){
        let work = oldAccountData.value.work_experience?JSON.parse(oldAccountData.value.work_experience):[];
        return work;
      })

      const compAccomplishments = computed(function(){
        let accomplishments = oldAccountData.value.accomplishments?JSON.parse(oldAccountData.value.accomplishments):[];
        return accomplishments;
      })


      const compQualificationCategory = computed(function(){
        let category = [];

        let qualifications = oldAccountData.value.qualifications?JSON.parse(oldAccountData.value.qualifications):[];

        for(let i = 0; i < qualifications.length ; i++){
            category.push(qualifications[i]["category"]);
        }

        category = [...new Set(category)];

        return category.sort();

    });


      const compQualifications = computed(function(){

        let qualifications = oldAccountData.value.qualifications?JSON.parse(oldAccountData.value.qualifications):[];

        return qualifications;

    });


    const compSkillsLevel = computed(function(){
      let level = [];

      let skills = oldAccountData.value.skills?JSON.parse(oldAccountData.value.skills):[];

      for(let i = 0; i < skills.length ; i++){
          level.push(skills[i]["level"]);
      }

      level = [...new Set(level)];

      level.sort();

      level.reverse();

      return level;

  });


    const compSkills = computed(function(){
        let skills = oldAccountData.value.skills?JSON.parse(oldAccountData.value.skills):[];
        return skills;
    });



      function updateWork(work){

          oldAccountData.value.work_experience = work;

      }

      function updateResume(item, data){

          oldAccountData['value'][item] = data;

      }

      const promptTitle = ref("")
      const promptContent = ref("")
      const promptClose = ref("No")

      function updateDeleteData(id, item){
          fv.deleteId.val = id;
          fv.deleteItem.val = item;
          promptTitle.value = `Delete ${item.replace("_"," ")}`;
          promptContent.value = `Are you sure you want to delete ${item.replace("_"," ")}`;
      }

      function closeDeleteBox(){
          fv.reqFeedback.msg = "";
          fv.reqFeedback.val = "";
          fv.showSubmit.status = true;
          promptClose.value = "No";
      }


      // create variables for the values to be submitted
     const fv = reactive({
         deleteId: {status:false, val:"", msg:false, validate:["required"]},
         deleteItem: {status:false, val:"", msg:false, validate:["required"]},
         reqFeedback : {status:false, val:"", msg:false},
         showSubmit : {status:true, val:true}
     })

      const monthList = [ "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];


      // create the account using async
      async function deleteProfileItem(){

          // end the action of making a submission, and hide loader
          fv.showSubmit.status = false;

          // load the form data , and append all the necessary fields
          let formData = new FormData();
          formData.append("fv_deleteId", fv.deleteId.val);
          formData.append("fv_deleteItem", fv.deleteItem.val);


          // post the data using axios
          try{
              const response = await axios.put(
                  coreUrl.backendUrl+'delete_individual_resume_details',
                  formData,
                  {
                      withCredentials: true,
                      headers:{
                         "Content-Type": "multipart/form-data"
                      }
                  }
              );

              // If successful, get the resppnse data
              const responseData = response.data;

              fv.reqFeedback.status = true;
              fv.reqFeedback.msg = responseData.message.req_msg;
              fv.reqFeedback.val = "success";

              oldAccountData['value'][fv.deleteItem.val] = responseData.data;

              promptClose.value = "Close";

          }catch(error){

              // load the error to the appropriate field
              const fv_msg = error.response.data.message.fv_msg;
              const req_msg = error.response.data.message.req_msg;

              for(const key in fv_msg){
                  if(fv[key]){
                      fv[key]["status"] = false;
                      fv[key]["msg"] = fv_msg[key];
                  }
              }


              fv.reqFeedback.status = true;
              fv.reqFeedback.msg = req_msg;
              fv.reqFeedback.val = "danger";

              // end the action of making a submission, and hide loader
              fv.showSubmit.status = true;

          }

      }


      return {
          fv,
          updateDeleteData,
          oldAccountData,
          renderOptions,
          compWorkExperience,
          compAccomplishments,
          compQualifications,
          compQualificationCategory,
          compSkills,
          compSkillsLevel,
          modalOptions,
          modalSize,
          modalTitle,
          updateBio,
          updateWork,
          updateResume,
          promptTitle,
          promptContent,
          promptClose,
          monthList,
          updateModalData,
          closeDeleteBox,
          deleteProfileItem
      }
  }
};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.update-style{
    font-weight:200;
    font-size:14px;
}

</style>
