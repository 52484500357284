<template>
  <form  @submit.prevent="createUpdateAccomplishment" >

            <div class="row px-3" >
                <div class="col-xl-12 col-lg-12 col-12 pt-3">
                    <div class="form-group">
                        <label :class="fv.type.msg?'text-danger':''" for="email">
                             What type of accomplishment is this?
                            <span v-if="fv.type.msg" > | {{ fv.type.msg }}</span>
                        </label>
                        <select class="form-control with-light" style="height:36px"
                                v-model.trim="fv.type.val"
                                @change="validate.validateFormData(fv,'type',true)">
                               <option value="">Select accomplishment type</option>
                               <option v-for="type in accomplishmentTypes"
                                       :value="type.value"
                                       :key="type.value"
                               >
                                   {{type.alias}}
                               </option>
                         </select>
                    </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-12 pt-3" v-if="fv.type.val">
                    <div >
                            <div class="form-group">
                                <label :class="fv.name.msg?'text-danger':''" for="organization">
                                    What is the name of your {{ fv.type.val.replace('_',' / ') }}
                                    <span v-if="fv.name.msg" > | {{ fv.name.msg }}</span>
                                </label>
                                <input type="text" :placeholder="'Enter ' + fv.type.val.replace('_',' / ') + ' name'"
                                       required v-model.trim="fv.name.val"
                                       @keyup="validate.validateFormData(fv,'name',true)"
                                       class="form-control with-light" id="email">
                            </div>
                    </div>

                    <div class="pt-3">
                        <div class="form-group">
                            <label :class="fv.type.msg?'text-danger':''" for="email">
                                 Who owns this {{ fv.type.val.replace('_',' / ') }}?
                                <span v-if="fv.type.msg" > | {{ fv.type.msg }}</span>
                            </label>

                            <span class="float-right">
                                <input type="checkbox"
                                       class="mt-2" v-model="fv.collaborators.val" />
                                Collaborators
                            </span>
                            <select class="form-control with-light" style="height:36px"
                                    v-model.trim="fv.owner.val"
                                    @change="validate.validateFormData(fv,'owner',true)">
                                   <option value="">Select owner</option>
                                   <option value="personal">Personal</option>
                                   <option value="external">External Organization</option>
                             </select>
                        </div>
                    </div>

                    <div class="pt-3" v-if="fv.owner.val == 'external'">
                            <div class="form-group">
                                <label :class="fv.client.msg?'text-danger':''" for="email">
                                    Client who owns the {{ fv.type.val.replace('_',' / ') }}
                                    <span v-if="fv.client.msg" > | {{fv.client.msg}}</span>
                                </label>
                                <input type="text" :placeholder="'Enter ' + fv.type.val.replace('_',' / ') + ' client'"
                                       required v-model.trim="fv.client.val"
                                       @keyup="validate.validateFormData(fv,'client',true)"
                                       class="form-control with-light" id="email">
                            </div>
                    </div>

                    <div class="pt-3">
                        <div class="form-group">
                            <label :class="fv.link.msg?'text-danger':''" for="password">
                                Link to {{ fv.type.val.replace('_',' / ') }} ( optional )
                                <span v-if="fv.link.msg" > | {{fv.link.msg}}</span>
                            </label>
                            <div class="input-group">
                                <div class="input-group-prepend rounded-left cursor-pointer">
                                    <span class="input-group-text b-0 rounded-right"
                                          id="basic-addon1" >
                                        http(s)://
                                    </span>
                                </div>
                                <input class="form-control with-light" required id="link" type="text"
                                       :class="fv.link.msg?'border-danger':''"
                                       v-model="fv.link.val"
                                       @keyup="validateLink"
                                       :placeholder="'Enter link to ' + fv.type.val.replace('_',' / ') ">

                            </div>
                        </div>
                    </div>

                    <div class="pt-3">
                        <div class="form-group">
                            <label :class="fv.year.msg?'text-danger':''" for="email">
                                Year
                                <span v-if="fv.year.msg" > | {{ fv.year.msg }}</span>
                            </label>
                            <select class="form-control with-light" style="height:36px"
                                    v-model.trim="fv.year.val"
                                    @change="validate.validateFormData(fv,'year',true)">
                                   <option value="">Select year</option>
                                   <option v-for="year in eligibleYears"
                                           :value="year"
                                           :key="year"
                                   >
                                       {{year}}
                                   </option>
                             </select>
                        </div>
                    </div>

                    <div class="pt-3">
                        <div class="form-group">
                            <label :class="fv.summary.msg?'text-danger':''" for="email">
                                A brief summary describing the {{ fv.type.val.replace('_',' / ') }} (optional)
                                <span v-if="fv.summary.msg" > | {{fv.summary.msg}}</span>
                            </label>
                            <textarea class="form-control with-light" v-model.trim="fv.summary.val"
                            @keyup="validate.validateFormData(fv,'summary',true)" rows="7"></textarea>
                        </div>
                    </div>

                </div>

                <div class="col-12" v-if="fv.reqFeedback.status">
                    <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                </div>

                <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                    <button type="submit" class="btn btn-save col-12">Update accomplishment</button>
                </div>
                <base-spinner class="ml-3" v-else></base-spinner>

            </div>
  </form>

</template>


<script>
import { ref,reactive,computed,inject } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation'

export default{
  name: 'CreateProfileAccomplishment',
  emits: ['emitUpdateResume'],
  props: {
      updateId : {
          type:String,
          default:"none"
      }
  },
  setup(props, { emit }) {

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      // to set the data of for the job ad
      const previousAccomplishments = ref(JSON.parse(store.getters["account/getAccountData"]["accomplishments"]));
      const updateAccomplishments =  Array.isArray(previousAccomplishments.value)?ref(previousAccomplishments.value.filter((item)=> item.id === props.updateId)):ref([]);

      // get the validators
      const validate = validators();

      // get the axios object
      const axios = inject('axios');  // inject axios

      //get the urls
      const coreUrl = inject('coreUrl');

      // create variables for the values to be submitted
       const fv = reactive({
           type : {status:false, val:props.updateId == "none" ?"":updateAccomplishments.value[0]["type"], msg:false, validate:["required","safeInput"]},
           name : {status:false, val:props.updateId == "none" ?"":updateAccomplishments.value[0]["name"], msg:false, validate:[]},
           owner : {status:false, val:props.updateId == "none" ?"":updateAccomplishments.value[0]["owner"], msg:false, validate:["required","safeInput"]},
           collaborators : {status:false, val:props.updateId == "none" ?false:updateAccomplishments.value[0]["collaborators"], msg:false, validate:["required"]},
           client : {status:false, val:props.updateId == "none" ?"":updateAccomplishments.value[0]["client"], msg:false, validate:["required"]},
           link : {status:false, val:props.updateId == "none" ?"":updateAccomplishments.value[0]["link"], msg:false, validate:["required","website"]},
           year: {status:false, val:props.updateId == "none" ?"":+updateAccomplishments.value[0]["year"], msg:false, validate:["required","numeric"]},
           summary : {status:false, val:props.updateId == "none" ?"":updateAccomplishments.value[0]["summary"], msg:false, validate:["required",{option:"textMax",val:300}]},
           reqFeedback : {status:false, val:"", msg:false},
           showSubmit : {status:false, val:true}
       })


       function validateLink(){
           fv.link.val=fv.link.val.replace(/https:\/\//g,"")
           fv.link.val=fv.link.val.replace(/http:\/\//g,"")
           validate.validateFormData(fv,'link',true)
       }


       const eligibleYears = computed(function(){

           let date = new Date()
           let years = []
           let currentYear = date.getFullYear();

           for( let i = +currentYear; i >=  +currentYear - 60; i-- ){
               years.push(i)
           }

           return years;
       })

       const accomplishmentTypes = ref([
           {value:"project",alias:"Project", projectNameEx:"technical & non-technical projects"},
           {value:"publication",alias:"Publication", publicationNameEx: "Reseach project, online articles"},
           {value:"honours_awards",alias:"Honours & Awards", honours_awardsNameEx: ""},
           {value:"patents",alias:"Patents" , patentsNameEx: "Patent name and ID number"}
       ]);



    // create the account using async
    async function createUpdateAccomplishment(){


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_accomplishId", props.updateId);
            formData.append("fv_type", fv.type.val);
            formData.append("fv_name", fv.name.val);
            formData.append("fv_owner", fv.owner.val);
            formData.append("fv_collaborators", fv.collaborators.val);
            formData.append("fv_client", fv.owner.val == 'personal'?"personal project":fv.client.val);
            formData.append("fv_link",  fv.link.val);
            formData.append("fv_year",  fv.year.val);
            formData.append("fv_summary", fv.summary.val);

            const updateLink = props.updateId == "none"?'create_individual_accomplishment_details':'edit_individual_accomplishment_details';

            // post the data using axios
            try{
                const response = await axios.put(
                    coreUrl.backendUrl+updateLink,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

            // If successful, get the resppnse data
            const responseData = response.data;

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = responseData.message.req_msg;
            fv.reqFeedback.val = "success";
            fv.showSubmit.status = false;


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;


            emit('emitUpdateResume', 'accomplishments', responseData.data)


        }catch(error){

            // load the error to the appropriate field
            const fv_msg = error.response.data.message.fv_msg;
            const req_msg = error.response.data.message.req_msg;

            for(const key in fv_msg){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = fv_msg[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

        }

    }

    return {
        fv,
        validate,
        validateLink,
        accomplishmentTypes,
        eligibleYears,
        createUpdateAccomplishment
     }
  }
}
</script>
