<template>
  <form  @submit.prevent="createUpdateSkill" >

            <div class="row px-3" >
                <div  class="col-xl-12 col-lg-12 col-12 pt-3" >
                            <div class="form-group">
                                <label :class="fv.skill.msg?'text-danger':''" for="title">
                                    Skill
                                    <span v-if="fv.skill.msg" > | {{ fv.skill.msg }}</span>
                                </label>
                                <input type="text" placeholder="Enter skill name e.g technical writing, php, javascript"
                                       required v-model.trim="fv.skill.val"
                                       @keyup="validate.validateFormData(fv,'title',true)"
                                       class="form-control with-light" id="title">
                            </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-12 pt-3">
                    <div class="form-group">
                        <label :class="fv.level.msg?'text-danger':''" for="email">
                             Skill Level
                            <span v-if="fv.level.msg" > | {{ fv.level.msg }}</span>
                        </label>
                        <select class="form-control with-light" style="height:36px"
                                v-model.trim="fv.level.val"
                                @change="validate.validateFormData(fv,'level',true)">
                               <option value="">Select skill type</option>
                               <option v-for="type in skillTypes"
                                       :value="type.value"
                                       :key="type.value"
                               >
                                   {{type.alias}}
                               </option>
                         </select>
                    </div>
                </div>


                <div class="col-12" v-if="fv.reqFeedback.status">
                    <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                </div>

                <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                    <button type="submit" class="btn btn-save col-12">Update skill</button>
                </div>
                <base-spinner class="ml-3" v-else></base-spinner>

            </div>
  </form>

</template>


<script>
import { ref,reactive,inject } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation'

export default{
  name: 'CreateProfileSkill',
  emits: ['emitUpdateResume'],
  props: {
      updateId : {
          level:String,
          default:"none"
      }
  },
  setup(props, { emit }) {

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      // to set the data of for the job ad
      const previousSkills = ref(JSON.parse(store.getters["account/getAccountData"]["skills"]));
      const updateSkills =  Array.isArray(previousSkills.value)?ref(previousSkills.value.filter((item)=> item.id === props.updateId)):ref([]);


      // get the validators
      const validate = validators();

      // get the axios object
      const axios = inject('axios');  // inject axios

      //get the urls
      const coreUrl = inject('coreUrl');

      // create variables for the values to be submitted
       const fv = reactive({
           level : {status:false, val:props.updateId == "none" ?"":updateSkills.value[0]["level"], msg:false, validate:["required","safeInput"]},
           skill : {status:false, val:props.updateId == "none" ?"":updateSkills.value[0]["skill"], msg:false, validate:["required","safeSpaceInput"]},
           reqFeedback : {status:false, val:"", msg:false},
           showSubmit : {status:false, val:true}
       })


       const skillTypes = ref([
           {value:"beginner",alias:"Beginner"},
           {value:"intermediate",alias:"Intermediate"},
           {value:"professional",alias:"Professional"}
       ]);



    // create the account using async
    async function createUpdateSkill(){


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_id", props.updateId);
            formData.append("fv_level", fv.level.val);
            formData.append("fv_skill", fv.skill.val);

            const updateLink = props.updateId == "none"?'create_individual_skill_details':'edit_individual_skill_details';

            // post the data using axios
            try{
                const response = await axios.put(
                    coreUrl.backendUrl+updateLink,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

            // If successful, get the resppnse data
            const responseData = response.data;

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = responseData.message.req_msg;
            fv.reqFeedback.val = "success";
            fv.showSubmit.status = false;


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;


            emit('emitUpdateResume', 'skills', responseData.data)


        }catch(error){

            // load the error to the appropriate field
            const fv_msg = error.response.data.message.fv_msg;
            const req_msg = error.response.data.message.req_msg;

            for(const key in fv_msg){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = fv_msg[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

        }

    }

    return {
        fv,
        validate,
        skillTypes,
        createUpdateSkill
     }
  }
}
</script>
