<template>
  <form  @submit.prevent="createUpdateProfile" >

            <div class="row px-3" >
                <div class="col-xl-12 col-lg-12 col-12 pt-3">
                    <div class="form-group">
                        <label :class="fv.type.msg?'text-danger':''" for="email">
                            Type of work
                            <span v-if="fv.type.msg" > | {{ fv.type.msg }}</span>
                        </label>
                        <select class="form-control with-light" style="height:36px"
                                v-model.trim="fv.type.val"
                                @change="validate.validateFormData(fv,'type',true)">
                               <option value="">Select job type</option>
                               <option v-for="type in jobTypeOptions"
                                       :value="type.value"
                                       :key="type.value"
                               >
                                   {{type.alias}}
                               </option>
                         </select>
                    </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-12 pt-3">
                        <div class="form-group">
                            <label :class="fv.organization.msg?'text-danger':''" for="organization">
                                Organization Name
                                <span v-if="fv.organization.msg" > | {{ fv.organization.msg }}</span>
                            </label>
                            <input type="text" placeholder="Enter name"
                                   required v-model.trim="fv.organization.val"
                                   @keyup="validate.validateFormData(fv,'organization',true)"
                                   class="form-control with-light" id="email">
                        </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-12 pt-3">
                        <div class="form-group">
                            <label :class="fv.title.msg?'text-danger':''" for="email">
                                Job role title
                                <span v-if="fv.title.msg" > | {{fv.title.msg}}</span>
                            </label>
                            <input type="text" placeholder="Enter name"
                                   required v-model.trim="fv.title.val"
                                   @keyup="validate.validateFormData(fv,'title',true)"
                                   class="form-control with-light" id="email">
                        </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
                        <div class="form-group">
                               <label :class="fv.startDate.msg?'text-danger':''" for="endDate">
                                 Start Date
                                <span v-if="fv.startDate.msg" > | {{fv.startDate.msg}}</span>
                            </label>
                            <span class="float-right">
                                <input type="checkbox"
                                       class="mt-2" v-model="fv.previousRole.val" />
                                previous job role
                            </span>
                            <Datepicker :monthPicker="true" v-model="fv.startDate.val" :format="startDateStringFormat">
                            </Datepicker>
                        </div>
                 </div>


                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3" v-if="fv.previousRole.val">
                         <div class="form-group">
                             <label :class="fv.endDate.msg?'text-danger':''" for="endDate">
                                 End Date
                                 <span v-if="fv.endDate.msg" > | {{fv.endDate.msg}}</span>
                             </label>
                             <Datepicker :monthPicker="true" v-model="fv.endDate.val" :format="endDateStringFormat">
                             </Datepicker>
                         </div>
                  </div>

                <div class="col-xl-12 col-lg-12 col-12 pt-3">
                    <div class="form-group">
                        <label :class="fv.summary.msg?'text-danger':''" for="email">
                            A brief summary of your knowledge, abilities, interests and experience
                            <span v-if="fv.summary.msg" > | {{fv.summary.msg}}</span>
                        </label>
                        <textarea class="form-control with-light" v-model.trim="fv.summary.val"
                        @keyup="validate.validateFormData(fv,'summary',true)" rows="7"></textarea>
                    </div>
                </div>



                <div class="col-12" v-if="fv.reqFeedback.status">
                    <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                </div>

                <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                    <button type="submit" class="btn btn-save col-12">Update account profile</button>
                </div>
                <base-spinner class="ml-3" v-else></base-spinner>

            </div>
  </form>

</template>


<script>
import { ref,reactive,inject } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation'
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';

export default{
  name: 'CreateProfileWork',
  emits: ['emitUpdateWork'],
  components: { Datepicker },
  props: {
      updateId : {
          type:String,
          default:"none"
      }
  },
  setup(props, { emit }) {

    // get the global vuex store values
    const store = useStore();

    // use the dispatch function to call an action that calls a mutation
    // to set the data of for the job ad
    const previousWorkExperience = ref(JSON.parse(store.getters["account/getAccountData"]["work_experience"]));
    const updateWorkExperience =  Array.isArray(previousWorkExperience.value)?ref(previousWorkExperience.value.filter((item)=> item.id === props.updateId)):ref([]);

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    console.log(props.updateId)

    // create variables for the values to be submitted
   const fv = reactive({
       type: {status:false, val:props.updateId == "none" ?"":updateWorkExperience.value[0]["type"], msg:false, validate:["required","safeInput"]},
       organization : {status:false, val:props.updateId == "none" ?"":updateWorkExperience.value[0]["organization"], msg:false, validate:["required",
                                                                "safeSpaceInput",
                                                                {option:"textMin",val:3},
                                                                {option:"textMax",val:140}
                                                               ]},
       title : {status:false, val:props.updateId == "none" ?"":updateWorkExperience.value[0]["title"], msg:false, validate:["required",
                                                                "safeSpaceInput",
                                                                {option:"textMin",val:3},
                                                                {option:"textMax",val:140}
                                                               ]},
       previousRole: {status:false, val:props.updateId == "none" ?false:JSON.parse(updateWorkExperience.value[0]["previousRole"]), msg:false, validate:["required","safeInput"]},
       startDate: {status:false, val:props.updateId == "none" ?"":JSON.parse(updateWorkExperience.value[0]["startDate"]), msg:false, validate:["required","safeInput"]},
       endDate : {status:false, val:props.updateId == "none" || updateWorkExperience.value[0]["endDate"]  == "inactive"?"":JSON.parse(updateWorkExperience.value[0]["endDate"]), msg:false, validate:["required"]},
       summary : {status:false, val:props.updateId == "none" ?"":updateWorkExperience.value[0]["summary"], msg:false, validate:["required"]},
       reqFeedback : {status:false, val:"", msg:false},
       showSubmit : {status:false, val:true}
   })


   const monthList = [ "January", "February", "March", "April", "May", "June",
   "July", "August", "September", "October", "November", "December"];


   function startDateStringFormat(newDate){

       const month = newDate.month;
       const year = newDate.year;

       return `Started in ${monthList[month]}-${year}`;

   }

   function endDateStringFormat(newDate){

       const month = newDate.month;
       const year = newDate.year;

       return `Ended in ${monthList[month]}-${year}`;

   }


   const jobTypeOptions = ref([
       {value:"full_time",alias:"Full-time"},
       {value:"part_time",alias:"Part-time"},
       {value:"consultancy",alias:"Consultancy"},
       {value:"freelance",alias:"Freelance"},
       {value:"internship",alias:"Internship"},
       {value:"contract",alias:"Contract"},
       {value:"volunteer",alias:"Volunteer"},
       {value:"self_employed",alias:"Self-employed"}
   ]);


    // create the account using async
    async function createUpdateProfile(){


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_workId", props.updateId);
            formData.append("fv_organization", fv.organization.val);
            formData.append("fv_title", fv.title.val);
            formData.append("fv_type", fv.type.val);
            formData.append("fv_previousRole", fv.previousRole.val);
            formData.append("fv_startDate", JSON.stringify(fv.startDate.val));
            formData.append("fv_endDate",  fv.previousRole.val?JSON.stringify(fv.endDate.val):'inactive');
            formData.append("fv_summary", fv.summary.val);

            const updateLink = props.updateId == "none"?'create_individual_work_details':'edit_individual_work_details';

            // post the data using axios
            try{
                const response = await axios.put(
                    coreUrl.backendUrl+updateLink,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

            // If successful, get the resppnse data
            const responseData = response.data;

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = responseData.message.req_msg;
            fv.reqFeedback.val = "success";
            fv.showSubmit.status = false;


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;


            emit('emitUpdateResume', 'work_experience', responseData.data)


        }catch(error){

            // load the error to the appropriate field
            const fv_msg = error.response.data.message.fv_msg;
            const req_msg = error.response.data.message.req_msg;

            for(const key in fv_msg){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = fv_msg[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

        }

    }

    return {
        fv,
        validate,
        startDateStringFormat,
        endDateStringFormat,
        jobTypeOptions,
        createUpdateProfile
     }
  }
}
</script>
